@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbS2lBkm8.woff2") format("woff2");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbTGlBkm8.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbQWlBkm8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbT2lB.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.8cb7366e.css.map */
